import React, { useState, useEffect } from "react";
import DisplaySimilarSongs from "./display_similarsongs";

const SimilarSongs = () => {
  return (
    <div>
      <DisplaySimilarSongs />
    </div>
  );
};

export default SimilarSongs;
